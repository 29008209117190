<template>
 <div class="">
  <div
   v-if="assignedFilterQueue && assignedFilterQueue.length"
   class="flex gap-2">
   <span
    v-for="search in assignedFilterQueue"
    :key="search"
    class="inline-flex items-center rounded-full bg-gray-200 py-0.5 lg:pl-2.5 pr-1 text-sm font-medium text-gray-700">
    {{ search }}
    <button
     type="button"
     @click="
      assignedFilterQueue && assignedFilterQueue.length > 1
       ? assignedFilterQueue.splice(assignedFilterQueue.indexOf(search), 1)
       : ((assignedFilterQueue.length = 0),
         this.$emit('update:searchQueue', null))
     "
     class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none">
     <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
     </svg>
    </button>
   </span>
  </div>
  <div
   v-if="assignedFilterQueueName && assignedFilterQueueName.length"
   class="flex gap-2 pt-1">
   <span
    v-for="search in assignedFilterQueueName"
    :key="search"
    class="inline-flex items-center rounded-full bg-gray-200 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700">
    {{ search }}
    <button
     type="button"
     @click="
      assignedFilterQueueName && assignedFilterQueueName.length > 1
       ? assignedFilterQueueName.splice(
          assignedFilterQueueName.indexOf(search),
          1
         )
       : ((assignedFilterQueueName.length = 0),
         this.$emit('update:searchQueueName', null))
     "
     class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none">
     <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
     </svg>
    </button>
   </span>
  </div>
  <div
   v-if="assignedFilterExtension && assignedFilterExtension.length"
   class="flex gap-2 pt-1">
   <span
    v-for="search in assignedFilterExtension"
    :key="search"
    class="inline-flex items-center rounded-full bg-gray-200 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700">
    {{ search }}
    <button
     type="button"
     @click="
      assignedFilterExtension && assignedFilterExtension.length > 1
       ? assignedFilterExtension.splice(
          assignedFilterExtension.indexOf(search),
          1
         )
       : ((assignedFilterExtension.length = 0),
         this.$emit('update:searchExtension', null))
     "
     class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none">
     <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
     </svg>
    </button>
   </span>
  </div>
  <div
   v-if="assignedFilterExtensionName && assignedFilterExtensionName.length"
   class="flex gap-2 pt-1">
   <span
    v-for="search in assignedFilterExtensionName"
    :key="search"
    class="inline-flex items-center rounded-full bg-gray-200 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700">
    {{ search }}
    <button
     type="button"
     @click="
      assignedFilterExtensionName && assignedFilterExtensionName.length > 1
       ? assignedFilterExtensionName.splice(
          assignedFilterExtensionName.indexOf(search),
          1
         )
       : ((assignedFilterExtensionName.length = 0),
         this.$emit('update:searchExtensionName', null))
     "
     class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none">
     <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
     </svg>
    </button>
   </span>
  </div>
 </div>
 <div class="flex flex-col">
  <div class="overflow-x-auto">
   <div class="inline-block min-w-full align-middle py-2 px-2">
    <div
     class="relative overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
     <!-- <div
      v-if="selectedPeople.length > 0"
      class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 dark:bg-slate-700 sm:left-16"
     >
      <button
       type="button"
       class="inline-flex items-center rounded border border-gray-300 bg-white dark:bg-slate-500 px-2.5 py-1.5 text-xs font-medium shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
      >
       Bulk edit
      </button>
      <button
       type="button"
       class="inline-flex items-center rounded border border-gray-300 bg-white dark:bg-slate-500 px-2.5 py-1.5 text-xs font-medium shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
      >
       Delete all
      </button>
     </div> -->
     <table
      v-if="tableData && tableData.length"
      class="min-w-full divide-y divide-gray-300 dark:divide-slate-400 h-96">
      <thead class="bg-gray-50 dark:bg-slate-700">
       <tr class="">
        <!-- <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
         <input
          type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
          :checked="indeterminate || selectedPeople.length === tableData.length"
          :indeterminate="indeterminate"
          @change="selectedPeople = $event.target.checked ? tableData.map((p) => p.id) : []"
         />
        </th> -->

        <th
         v-for="head in header"
         :key="head"
         scope="col"
         :class="[
          head.text !== 'declined' &&
          head.text !== 'abandoned' &&
          head.text !== 'answered'
           ? 'text-gray-900 dark:text-slate-200'
           : head.text === 'declined' || head.text === 'abandoned'
           ? 'text-red-600'
           : head.text === 'answered'
           ? 'text-green-600'
           : 'text-gray-900 dark:text-slate-200',
          'px-1 py-3 text-center text-sm font-semibold ',
         ]">
         <div
          @click="
           head.key &&
           head.text !== 'talking_duration' &&
           head.text !== 'waiting_duration'
            ? setSorting(head.key)
            : ''
          "
          :class="
           head.key === this.activeSorting
            ? `underline`
            : head.text === 'queue' ||
              head.text === 'queue_name' ||
              head.text === 'extension' ||
              head.text === 'element_name'
            ? ''
            : 'flex w-auto text-center justify-center'
          "
          :title="$t(head.info)">
          <span v-if="head.text === 'time'">
           <span v-if="selectedtime && selectedtime.length">
            {{ $t(head.text) }}
           </span>
          </span>

          <div class="text-center" v-else-if="head.text === 'queue'">
           <div as="div" class="mt-0">
            <div class="text-center">
             <button
              class="flex text-sm text-center justify-center font-semibold capitalize"
              @focus="openQueueFilter = true"
              @blur="openQueueFilter = false"
              @click="focusInputQueue()">
              {{ $t(head.text) }}
              <FilterIcon
               class="flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500"
               aria-hidden="true" />
             </button>
            </div>
            <transition
             enter-active-class="transition ease-out duration-100"
             enter-from-class="transform opacity-0 scale-95"
             enter-to-class="transform opacity-100 scale-100"
             leave-active-class="transition ease-in duration-75"
             leave-from-class="transform opacity-100 scale-100"
             leave-to-class="transform opacity-0 scale-95">
             <div
              @mouseover="
               (openQueueFilterFocus = true), (isMouseOverFilter = true)
              "
              @mouseleave="
               (isMouseOverFilter = false), (openQueueFilterFocus = false)
              "
              v-show="openQueueFilter || openQueueFilterFocus"
              class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-1">
              <div
               class="mt-1 mb-1 mx-1 rounded-md px-3 py-2 shadow-sm text-gray-800">
               <div class="relative">
                <div
                 class="pointer-events-none absolute inset-y-0 left-0 pl-3 text-center justify-center flex items-center z-2">
                 <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <vue-select
                 @focus="
                  (openQueueFilter = true), (openQueueFilterFocus = true)
                 "
                 @blur="openQueueFilter = false"
                 v-model="assignedFilterQueue"
                 :options="queuesNameNumberArray"
                 multiple
                 maxHeight="200"
                 close-on-select="true"
                 searchable
                 :class="[
                  'block w-full bg-white py-1 pl-10 pr-3 text-sm sm:text-sm z-1',
                 ]"
                 ref="inputSearchQueue">
                </vue-select>
               </div>
              </div>
             </div>
            </transition>
           </div>
          </div>
          <div class="" v-else-if="head.text === 'queue_name'">
           <div as="div" class="mt-0">
            <div class="">
             <button
              class="flex text-center justify-center text-sm font-semibold capitalize"
              @focus="openQueueNameFilter = true"
              @blur="openQueueNameFilter = false"
              @click="focusInputQueueName()">
              {{ $t(head.text) }}
              <FilterIcon
               class="flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500"
               aria-hidden="true" />
             </button>
            </div>
            <transition
             enter-active-class="transition ease-out duration-100"
             enter-from-class="transform opacity-0 scale-95"
             enter-to-class="transform opacity-100 scale-100"
             leave-active-class="transition ease-in duration-75"
             leave-from-class="transform opacity-100 scale-100"
             leave-to-class="transform opacity-0 scale-95">
             <div
              @mouseover="
               (openQueueNameFilterFocus = true), (isMouseOverFilter = true)
              "
              @mouseleave="
               (isMouseOverFilter = false), (openQueueNameFilterFocus = false)
              "
              v-show="openQueueNameFilter || openQueueNameFilterFocus"
              class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-1">
              <div
               class="mt-1 mb-1 mx-1 rounded-md px-3 py-2 shadow-sm text-gray-800">
               <div class="relative">
                <div
                 class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-2">
                 <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <vue-select
                 @focus="
                  (openQueueNameFilter = true),
                   (openQueueNameFilterFocus = true)
                 "
                 @blur="openQueueNameFilter = false"
                 v-model="assignedFilterQueueName"
                 :options="queuesNameArray"
                 multiple
                 maxHeight="200"
                 close-on-select="true"
                 searchable
                 :class="[
                  'block w-full bg-white py-1 pl-10 pr-3 text-sm sm:text-sm z-1',
                 ]"
                 ref="inputSearchQueueName">
                </vue-select>
               </div>
              </div>
             </div>
            </transition>
           </div>
          </div>
          <div class="" v-else-if="head.text === 'extension'">
           <div as="div" class="mt-0">
            <div class="">
             <button
              class="flex text-center justify-center text-sm font-semibold capitalize"
              @focus="openExtensionFilter = true"
              @blur="openExtensionFilter = false"
              @click="focusInputExtension()">
              {{ $t(head.text) }}
              <FilterIcon
               class="flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500"
               aria-hidden="true" />
             </button>
            </div>
            <transition
             enter-active-class="transition ease-out duration-100"
             enter-from-class="transform opacity-0 scale-95"
             enter-to-class="transform opacity-100 scale-100"
             leave-active-class="transition ease-in duration-75"
             leave-from-class="transform opacity-100 scale-100"
             leave-to-class="transform opacity-0 scale-95">
             <div
              @mouseover="
               (openExtensionFilterFocus = true), (isMouseOverFilter = true)
              "
              @mouseleave="
               (isMouseOverFilter = false), (openExtensionFilterFocus = false)
              "
              v-show="openExtensionFilter || openExtensionFilterFocus"
              class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-1">
              <div
               class="mt-1 mb-1 mx-1 rounded-md px-3 py-2 shadow-sm text-gray-800">
               <div class="relative">
                <div
                 class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-2">
                 <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <vue-select
                 @focus="
                  (openExtensionFilter = true),
                   (openExtensionFilterFocus = true)
                 "
                 @blur="openExtensionFilter = false"
                 v-model="assignedFilterExtension"
                 multiple
                 :options="extensionsNameNumberArray"
                 maxHeight="200"
                 close-on-select="true"
                 searchable
                 :class="[
                  'block w-full bg-white py-1 pl-10 pr-3 text-sm sm:text-sm z-1',
                 ]"
                 ref="inputSearchExtension">
                </vue-select>
               </div>
              </div>
             </div>
            </transition>
           </div>
          </div>
          <div class="" v-else-if="head.text === 'element_name'">
           <div as="div" class="mt-0">
            <div class="">
             <button
              class="flex text-center justify-center text-sm font-semibold capitalize"
              @focus="openExtensionNameFilter = true"
              @blur="openExtensionNameFilter = false"
              @click="focusInputExtensionName()">
              {{ $t(head.text) }}
              <FilterIcon
               class="flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500"
               aria-hidden="true" />
             </button>
            </div>
            <transition
             enter-active-class="transition ease-out duration-100"
             enter-from-class="transform opacity-0 scale-95"
             enter-to-class="transform opacity-100 scale-100"
             leave-active-class="transition ease-in duration-75"
             leave-from-class="transform opacity-100 scale-100"
             leave-to-class="transform opacity-0 scale-95">
             <div
              @mouseover="
               (openExtensionNameFilterFocus = true), (isMouseOverFilter = true)
              "
              @mouseleave="
               (isMouseOverFilter = false),
                (openExtensionNameFilterFocus = false)
              "
              v-show="openExtensionNameFilter || openExtensionNameFilterFocus"
              class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-1">
              <div
               class="mt-1 mb-1 mx-1 rounded-md px-3 py-2 shadow-sm text-gray-800 w-full">
               <div class="relative w-full">
                <div
                 class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-2">
                 <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <vue-select
                 @focus="
                  (openExtensionNameFilter = true),
                   (openExtensionNameFilterFocus = true)
                 "
                 @blur="openExtensionNameFilter = false"
                 v-model="assignedFilterExtensionName"
                 multiple
                 :options="extensionsNameArray"
                 close-on-select="true"
                 maxHeight="200"
                 searchable
                 :class="[
                  'block w-full bg-white py-1 pl-10 pr-3 text-sm sm:text-sm z-1',
                 ]"
                 ref="inputSearchExtensionName">
                </vue-select>
               </div>
              </div>
             </div>
            </transition>
           </div>
          </div>
          <div class="" v-else-if="head.text === 'talking_duration'">
           <div class="inline-block">
            <div
             @click="head.key ? setSorting(head.key) : ''"
             class="text-center justify-center pb-2 flex gap-1">
             <span>Durée d’appel</span>
             <div
              class="flex relative text-center justify-center"
              :title="head.info ? head.info : ''">
              <ArrowNarrowDownIcon
               v-if="this.currentSortDir === 'desc'"
               :class="[
                head.key === this.activeSorting ? `underline` : '',
                'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
               ]"
               aria-hidden="true" />
              <ArrowNarrowUpIcon
               v-else-if="this.currentSortDir === 'asc'"
               :class="[
                head.key === this.activeSorting ? `underline` : '',
                'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
               ]"
               aria-hidden="true" />
             </div>
            </div>
            <select
             v-model="talking_duration_option"
             name="talking_duration_option"
             class="text-sm py-0.5 px-1 w-auto font-bold border-0 bg-transparent">
             <option value="total">
              {{ "Total" }}
             </option>
             <option value="avg">
              {{ "Moyenne" }}
             </option>
            </select>
           </div>
          </div>
          <div class="" v-else-if="head.text === 'waiting_duration'">
           <div class="inline-block">
            <div
             @click="head.key ? setSorting(head.key) : ''"
             class="text-center justify-center pb-2 flex gap-1"
             title="La durée d'attente est calculée uniquement sur la base des appels répondus. Les appels non répondus ne sont pas pris en compte. Dans les files d'appel, la durée d'attente inclut le temps de lecture du message de prédécroché, si celui-ci est activé sur la file.">
             <span> Durée d’attente </span>
             <div
              class="flex relative text-center justify-center"
              :title="head.info ? head.info : ''">
              <ArrowNarrowDownIcon
               v-if="this.currentSortDir === 'desc'"
               :class="[
                head.key === this.activeSorting ? `underline` : '',
                'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
               ]"
               aria-hidden="true" />
              <ArrowNarrowUpIcon
               v-else-if="this.currentSortDir === 'asc'"
               :class="[
                head.key === this.activeSorting ? `underline` : '',
                'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
               ]"
               aria-hidden="true" />
             </div>
            </div>
            <select
             v-model="waiting_duration_option"
             name="waiting_duration_option"
             class="text-sm py-0.5 px-1 w-auto font-bold border-0 bg-transparent">
             <option value="total">
              {{ "Total" }}
             </option>
             <option value="avg">
              {{ "Moyenne" }}
             </option>
            </select>
            <!-- <div class="inline-flex gap-10 text-blue-700">
             <span
              title="Temps de sonnerie totale de la ressource pour la période considérée."
              >{{ "Total" }}
              <span class="absolute">
               <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-3.5 h-3.5 text-blue-700">
                <path
                 fill-rule="evenodd"
                 d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                 clip-rule="evenodd"
                 title="Temps de sonnerie totale de la ressource pour la période considérée." />
               </svg>
              </span>
             </span>
             <span
              title="Temps de sonnerie totale de la resource, pour la période considérée, rapporté au nombre d'appels présentés (répondus ou non répondus)."
              >{{ "Moyenne" }}
              <span class="absolute">
               <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-3.5 h-3.5 text-blue-700">
                <path
                 fill-rule="evenodd"
                 d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                 clip-rule="evenodd"
                 title="Temps de sonnerie totale de la resource, pour la période considérée, rapporté au nombre d'appels présentés (répondus ou non répondus)." />
               </svg>
              </span>
             </span>
            </div> -->
           </div>
          </div>
          <div v-else-if="head.text === 'ranges'" class="w-full">
           <div class="inline-block w-full text-center">
            <div class="pb-2 text-center">
             <span class="relative" :title="head.info ? head.info : ''">
              Nombre d’appels répondus dans un délai de
              <!-- <svg
               v-if="head.hasInfo"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20"
               fill="currentColor"
               class="w-3.5 h-3.5 text-blue-700 absolute -right-3 -top-1">
               <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                clip-rule="evenodd"
                :title="head.info" />
              </svg> -->
             </span>
            </div>

            <div :class="`lg:grid grid-cols-${finalHeader.length} gap-0`">
             <span v-for="range in finalHeader" :key="range">{{ range }}</span>
            </div>
           </div>
          </div>
          <div
           class="capitalize flex relative text-center justify-center items-center"
           v-else-if="head.text === 'date'"
           :title="head.info ? head.info : ''">
           <span v-if="!groupData">
            {{ $t(head.text) }}
           </span>
           <ArrowNarrowDownIcon
            v-if="this.currentSortDir === 'desc' && !groupData"
            :class="[
             head.key === this.activeSorting ? `underline` : '',
             'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
            ]"
            aria-hidden="true" />
           <ArrowNarrowUpIcon
            v-else-if="this.currentSortDir === 'asc' && !groupData"
            :class="[
             head.key === this.activeSorting ? `underline` : '',
             'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
            ]"
            aria-hidden="true" />
           <!-- <svg
             v-if="head.hasInfo"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             fill="currentColor"
             class="w-3.5 h-3.5 text-blue-700 absolute -right-3.5">
             <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
              clip-rule="evenodd"
              :title="head.info" />
            </svg> -->
          </div>
          <div v-else-if="head.text === 'declined'" class="w-full">
           <div class="inline-block w-full text-center">
            <div class="pb-2 text-center">
             <span
              class="relative flex justify-center"
              :title="head.info ? head.info : ''">
              Non répondus
              <!-- <svg
               v-if="head.hasInfo"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20"
               fill="currentColor"
               class="w-3.5 h-3.5 text-blue-700 absolute -right-3 -top-1">
               <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                clip-rule="evenodd"
                :title="head.info" />
              </svg> -->
              <ArrowNarrowDownIcon
               v-if="this.currentSortDir === 'desc'"
               :class="[
                head.key === this.activeSorting ? `underline` : '',
                'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
               ]"
               aria-hidden="true" />
              <ArrowNarrowUpIcon
               v-else-if="this.currentSortDir === 'asc'"
               :class="[
                head.key === this.activeSorting ? `underline` : '',
                'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
               ]"
               aria-hidden="true" />
             </span>
            </div>

            <div :class="`lg:grid grid-cols-2 gap-0 text-left`">
             <!-- <span v-for="range in finalHeader" :key="range">{{ range }}</span> -->
             <div
              class="flex justify-center items-center"
              :title="head.info ? head.info : ''">
              {{ $t("Déclinés") }}
              <!-- <svg
             v-if="head.key"
             xmlns="http://www.w3.org/2000/svg"
             :class="[
              head.key === this.activeSorting ? `underline` : '',
              'ml- h-5 w-5',
             ]"
             viewBox="0 0 20 20"
             fill="currentColor">
             <path
              :d="[
               'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
              ]" />
            </svg> -->
             </div>
             <div
              class="flex justify-center"
              :title="`L'appelant raccroche sans que l'appel ait été répondu par un agent. Cette statistique ne parait que dans une file`">
              {{ $t("Abandonnés") }}
              <!-- <svg
             v-if="head.key"
             xmlns="http://www.w3.org/2000/svg"
             :class="[
              head.key === this.activeSorting ? `underline` : '',
              'ml- h-5 w-5',
             ]"
             viewBox="0 0 20 20"
             fill="currentColor">
             <path
              :d="[
               'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
              ]" />
            </svg> -->
             </div>
            </div>
           </div>
          </div>
          <span v-else>
           <div
            class="capitalize flex relative text-center justify-center"
            v-if="head.text !== 'abandoned'"
            :title="head.info ? head.info : ''">
            {{ $t(head.text) }}
            <!-- <svg
             v-if="head.hasInfo"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             fill="currentColor"
             class="w-3.5 h-3.5 text-blue-700 absolute -right-3.5">
             <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
              clip-rule="evenodd"
              :title="head.info" />
            </svg> -->
            <ArrowNarrowDownIcon
             v-if="this.currentSortDir === 'desc'"
             :class="[
              head.key === this.activeSorting ? `underline` : '',
              'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
             ]"
             aria-hidden="true" />
            <ArrowNarrowUpIcon
             v-else-if="this.currentSortDir === 'asc'"
             :class="[
              head.key === this.activeSorting ? `underline` : '',
              'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
             ]"
             aria-hidden="true" />
           </div>
          </span>
         </div>
        </th>

        <!-- <th
         scope="col"
         class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 capitalize"
        >
         <div>test</div>
        </th> -->
       </tr>
      </thead>
      <tbody
       class="divide-y divide-gray-200 bg-white dark:bg-slate-500 dark:divide-slate-400">
       <tr
        v-for="data in dataDurationRanges"
        :key="data.id"
        :class="[
         data.queue == data.element && !queuesOnlyStats ? '' : '',
         'hover:bg-slate-100 dark:hover:bg-slate-400 cursor-pointer',
        ]">
        <!-- <td class="relative w-12 px-6 sm:w-16 sm:px-8">
         <div
          v-if="selectedPeople.includes(data.id)"
          class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
         ></div>
         <input
          type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
          :value="data.id"
          v-model="selectedPeople"
         />
        </td> -->
        <td
         @click="$emit('clickOn', data.id)"
         v-for="(info, idx) in body"
         :key="info"
         :class="[
          data.queue == data.element
           ? 'text-gray-500 dark:text-slate-100'
           : 'bg-gray-100 dark:bg-slate-400 border-t-2 border-b-2 text-gray-700 font-medium',
          'whitespace-nowrap px-1 py-3 text-sm max__body__width truncate text-center',
          ,
         ]"
         :title="header[idx]?.text !== 'declined' ? $t(header[idx]?.text) : ''">
         <span
          :class="[
           data.queue == data.element &&
           (info.includes('calls') || info.includes('solicited'))
            ? ''
            : getColumnColor(info, header[idx]?.text, data),
           'rounded-full px-2 py-1',
          ]">
          <span v-if="info.includes('duration')">
           <div class="inline-flex gap-5">
            <span
             v-if="
              talking_duration_option === 'total' && info.includes('talking')
             ">
             {{
              data[info]
               ? parseInt(data[info]) < 3600
                 ? this.formatSecsToMinAndSecs(data[info])
                 : this.formatSecsToHourMinAndSecs(parseInt(data[info]))
               : 0
             }}
            </span>
            <span
             v-if="
              waiting_duration_option === 'total' && info.includes('waiting')
             ">
             {{
              data[info]
               ? parseInt(data[info]) < 3600
                 ? this.formatSecsToMinAndSecs(data[info])
                 : this.formatSecsToHourMinAndSecs(parseInt(data[info]))
               : 0
             }}
            </span>
            <span
             v-if="
              info === 'talking_duration' && talking_duration_option === 'avg'
             ">
             {{
              data["talking_duration"] &&
              parseInt(data["talking_duration"]) > 0 &&
              data["answered"] &&
              parseInt(data["answered"]) > 0
               ? this.formatSecsToMinAndSecs(
                  parseInt(data["talking_duration"]) /
                   parseInt(data["answered"])
                 )
               : 0
             }}
            </span>
            <span
             v-else-if="
              info === 'waiting_duration' && waiting_duration_option === 'avg'
             ">
             <span v-if="data['answered']">
              {{
               data["waiting_duration"] &&
               parseInt(data["waiting_duration"]) > 0 &&
               data["answered"] &&
               parseInt(data["answered"]) > 0
                ? this.formatSecsToMinAndSecs(
                   parseInt(data["waiting_duration"]) /
                    parseInt(data["answered"])
                  )
                : 0
              }}
             </span>
             <span v-else class="flex justify-center text-center items-center">
              <MinusSmIcon
               class="mt-0.5 h-4 w-4 hover:text-gray-500"
               aria-hidden="true" />
             </span>
            </span>
           </div>
          </span>
          <span v-else-if="info === 'answered_percentage_ext'">
           <span v-if="data['calls']">
            {{
             (
              (parseInt(data["answered"] ? data["answered"] : 0) /
               (data["calls"] ? parseInt(data["calls"]) : 0)) *
              100
             ).toFixed(0)
            }}%
           </span>
           <span v-else class="flex justify-center text-center items-center">
            <MinusSmIcon
             class="mt-0.5 h-4 w-4 hover:text-gray-500"
             aria-hidden="true" />
           </span>
          </span>
          <span v-else-if="info === 'answered_percentage_queue'">
           {{
            (
             (parseInt(data["answered"] ? data["answered"] : 0) /
              (data["calls"] ? parseInt(data["calls"]) : 0)) *
             100
            ).toFixed(0)
           }}%
          </span>
          <span v-else-if="info === 'formatedTime'">
           <span v-if="selectedtime && selectedtime.length">
            {{ data[info] ? data[info] : 0 }}
           </span>
          </span>
          <span v-else-if="info === 'solicited'">
           <span>
            {{ data["solicited"] ? parseInt(data["solicited"]) : 0 }}
           </span>
          </span>
          <span v-else-if="info === 'calls_ext'">
           <span>
            {{ data["calls"] ? parseInt(data["calls"]) : 0 }}
           </span>
          </span>
          <span v-else-if="info === 'time' && data['time']">
           <span>
            {{
             parseInt(data["time"].slice(0, 2)) - this.hostTimeZone >= 10
              ? parseInt(data["time"].slice(0, 2)) -
                this.hostTimeZone +
                ":" +
                data["time"].slice(-2)
              : "0" +
                (parseInt(data["time"].slice(0, 2)) - this.hostTimeZone) +
                ":" +
                data["time"].slice(-2)
            }}
           </span>
          </span>
          <span v-else-if="info === 'answered' && !queuesOnlyStats">
           <span>
            {{
             data["keyType"] == 0
              ? data[info]
                ? parseInt(data[info])
                : 0
              : data["answered"]
              ? parseInt(data["answered"])
              : 0
            }}
           </span>
          </span>
          <span
           v-else-if="info === 'declined_solicitations'"
           :class="`lg:grid grid-cols-2 gap-0 text-center`">
           <span title="Déclinés">
            {{
             data["keyType"] == 0
              ? (parseInt(data["solicited"])
                 ? parseInt(data["solicited"])
                 : 0) -
                 ((parseInt(data["answered"])
                  ? parseInt(data["answered"])
                  : 0) +
                  (parseInt(data["abandoned"])
                   ? parseInt(data["abandoned"])
                   : 0)) >=
                0
                ? (parseInt(data["solicited"])
                   ? parseInt(data["solicited"])
                   : 0) -
                  ((parseInt(data["answered"])
                   ? parseInt(data["answered"])
                   : 0) +
                   (parseInt(data["abandoned"])
                    ? parseInt(data["abandoned"])
                    : 0))
                : 0
              : parseInt(data["solicited"])
              ? (parseInt(data["solicited"])
                 ? parseInt(data["solicited"])
                 : 0) -
                 ((parseInt(data["answered"])
                  ? parseInt(data["answered"])
                  : 0) +
                  (parseInt(data["abandoned"])
                   ? parseInt(data["abandoned"])
                   : 0)) >=
                0
                ? (parseInt(data["solicited"])
                   ? parseInt(data["solicited"])
                   : 0) -
                  ((parseInt(data["answered"])
                   ? parseInt(data["answered"])
                   : 0) +
                   (parseInt(data["abandoned"])
                    ? parseInt(data["abandoned"])
                    : 0))
                : 0
              : data["calls"]
              ? parseInt(data["calls"]) -
                parseInt(data["answered"]) -
                parseInt(data["abandoned"])
              : 0
            }}
           </span>
           <span title="Abandonnés">
            <span v-if="data['keyType'] == 4 || data['abandoned'] > 0">
             {{ data["abandoned"] ? data["abandoned"] : 0 }}
            </span>
            <span v-else class="flex justify-center text-center items-center">
             <MinusSmIcon
              class="mt-0.5 h-4 w-4 hover:text-gray-500"
              aria-hidden="true" />
            </span>
           </span>
          </span>
          <span
           v-else-if="info === 'elementNameNumber'"
           class="truncate"
           :title="
            data['keyType'] == 0 ? (data[info] ? data[info] : 0) : 'Full Queue'
           ">
           <span
            v-if="data['keyType'] != 0"
            class="flex justify-center text-center items-center">
            <MinusSmIcon
             class="mt-0.5 h-4 w-4 hover:text-gray-500"
             aria-hidden="true" />
           </span>
           <span
            v-else
            :title="
             data['keyType'] == 0 ? (data[info] ? data[info] : 0) : 'Full Queue'
            ">
            {{
             data["keyType"] == 0 ? (data[info] ? data[info] : 0) : "Full Queue"
            }}
           </span>
          </span>
          <span
           v-else-if="info === 'ranges'"
           :class="`lg:grid grid-cols-${finalBody.length} gap-0 text-center`">
           <div v-for="range in finalBody" :key="range" class="truncate">
            <span v-if="range.includes('talking')">
             {{ data[range] ? data[range] : 0 }} ({{
              parseInt(data[range] ? data[range] : 0) /
              (data["answered"] ? parseInt(data["answered"]) : 0)
               ? (
                  (parseInt(data[range] ? data[range] : 0) /
                   (data["answered"] ? parseInt(data["answered"]) : 0)) *
                  100
                 ).toFixed(0)
               : "0"
             }}%)
            </span>
            <span v-if="range.includes('answering')">
             {{ data[range] ? data[range] : 0 }}
             ({{
              (parseInt(data[range] ? data[range] : 0) /
               (data["answered"] ? parseInt(data["answered"]) : 0)) %
               1 ==
              0
               ? parseInt(data[range] ? data[range] : 0) /
                 (data["answered"] ? parseInt(data["answered"]) : 0)
                 ? (
                    (parseInt(data[range] ? data[range] : 0) /
                     (data["answered"] ? parseInt(data["answered"]) : 0)) *
                    100
                   ).toFixed(0)
                 : "0"
               : parseInt(data[range] ? data[range] : 0) /
                 (data["answered"] ? parseInt(data["answered"]) : 0)
               ? (
                  (parseInt(data[range] ? data[range] : 0) /
                   (data["answered"] ? parseInt(data["answered"]) : 0)) *
                  100
                 ).toFixed(1)
               : "0"
             }}%)
            </span>
           </div>
          </span>
          <span v-else>
           <span
            class="truncate"
            v-if="info !== 'time' && info !== 'day' && info !== 'abandoned'">
            {{ data[info] ? data[info] : 0 }}
           </span>
           <span
            v-if="info === 'day'"
            :title="
             data[info] ? formatDateNoTime(data[info]) : parseDateRange(period)
            ">
            {{
             data[info] ? formatDateNoTime(data[info]) : parseDateRange(period)
            }}
           </span>
           <span
            v-if="
             info === 'time' &&
             !data['time'] &&
             selectedtime &&
             selectedtime.length > 1
            ">
            {{
             selectedtime[0].slice(0, 2) +
             ":" +
             selectedtime[0].slice(-2) +
             "-" +
             selectedtime[1].slice(0, 2) +
             ":" +
             selectedtime[1].slice(-2)
            }}
           </span>
          </span>
         </span>
        </td>
       </tr>
      </tbody>
      <!-- <tbody
       v-else
       class="divide-y divide-gray-200 bg-white dark:bg-slate-500 dark:divide-slate-400"
      >
       <tr :class="['hover:bg-slate-100 dark:hover:bg-slate-400 cursor-pointer']">
        <div class="text-center p-10">
         <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          class="mx-auto h-12 w-12 text-gray-400"
         >
          <path
           stroke-linecap="round"
           stroke-linejoin="round"
           d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
          />
         </svg>
         <h3 class="mt-2 text-lg font-medium text-gray-900">
          {{ $t("noDataFoundTitle") }}
         </h3>
         <p class="mt-1 text-base text-gray-500"></p>
        </div>
       </tr>
      </tbody> -->
     </table>
     <div v-else class="text-center p-10 bg-white">
      <svg
       xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 24 24"
       stroke-width="1"
       stroke="currentColor"
       class="mx-auto h-12 w-12 text-gray-400">
       <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
      </svg>
      <h3 class="mt-2 text-lg font-medium text-gray-900">
       {{ $t("noDataFoundTitle") }}
      </h3>
      <p class="mt-1 text-base text-gray-500"></p>
     </div>
     <!-- <BackendTablePagination
      :to="this.to"
      :from="this.from"
      :total="this.total"
      :currentPage="currentPage"
      :links="links"
      @select-page="$emit('selectPage', $event)"
      @select-rows="$emit('selectRows', $event)"
     /> -->
    </div>
   </div>
  </div>
 </div>
</template>

<script>
// import BackendTablePagination from "./BackendTablePagination.vue";
import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";

import {
 FilterIcon,
 ArrowNarrowDownIcon,
 ArrowNarrowUpIcon,
} from "@heroicons/vue/outline";
// import { FilterIcon } from "@vue-hero-icons/outline"
import { MinusSmIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
export default {
 props: [
  "header",
  "body",
  "tableData",
  "to",
  "from",
  "total",
  "currentPage",
  "links",
  "selectedPeople",
  "indeterminate",
  "selectedtime",
  "queuesNameArray",
  "queuesArray",
  "extensionsArray",
  "extensionsNameArray",
  "searchQueue",
  "searchQueueName",
  "searchExtension",
  "searchExtensionName",
  "extensionsNameNumberArray",
  "queuesNameNumberArray",
  "period",
  "parseDateRange",
  "queuesOnlyStats",
  "timeRange",
  "timeGap",
  "numberOfColumns",
  "selectedRangeType",
  "concatenateData",
  "currentSortDir",
  "groupData",
  "didsOnlyStats",
 ],
 components: {
  VueSelect,
  FilterIcon,
  MinusSmIcon,
  ArrowNarrowDownIcon,
  ArrowNarrowUpIcon,
 },
 data() {
  return {
   talking_duration_option: "avg",
   waiting_duration_option: "avg",
   selectedPeople: [],
   assignedFilterQueue: [],
   assignedFilterQueueName: [],
   assignedFilterExtension: [],
   assignedFilterExtensionName: [],
   openQueueFilter: false,
   openQueueFilterFocus: false,
   openQueueNameFilter: false,
   openQueueNameFilterFocus: false,
   openExtensionFilter: false,
   openExtensionFilterFocus: false,
   openExtensionNameFilter: false,
   openExtensionNameFilterFocus: false,
   isMouseOverFilter: false,
   activeSorting: "",
  };
 },
 methods: {
  getColumnColor(info, column, data) {
   let keyType = data?.keyType;
   if (!this.pbx3cxTableConfig) {
    return false;
   }
   let val = null;
   switch (column) {
    case "answered_percentage":
     val = (data["answered"] / data["calls"]) * 100;
     return this.getAnsweredPercentageColor(val);
    case "waiting_duration":
     val = data["answered"] ? data["waiting_duration"] / data["answered"] : 0;
     if (keyType == 4) {
      return val ? this.getDurationAvgAnswerColor(val) : "";
     } else {
      return val ? this.getDurationAvgAnswerColorUser(val) : "";
     }
    case "talking_duration":
     val = data["talking_duration"] / data["answered"];
     return this.getDurationAvgCallColor(val);
    case "calls":
     val = data["solicited"] / data["calls"];
     return this.getRatioSolicitationsColor(val);
    case "solicited":
     val = data["solicited"] / data["calls"];
     return this.getRatioSolicitationsColor(val);
    default:
     return "";
   }
  },
  getAnsweredPercentageColor(value) {
   if (
    value <= this.pbx3cxTableConfig?.answered_percentage_red_value &&
    this.pbx3cxTableConfig?.answered_percentage_red_active
   ) {
    return "text-red-500 bg-red-200 font-semibold";
   } else if (
    value > this.pbx3cxTableConfig?.answered_percentage_red_value &&
    value <= this.pbx3cxTableConfig?.answered_percentage_orange_value &&
    this.pbx3cxTableConfig?.answered_percentage_orange_active
   ) {
    return "text-orange-600 bg-orange-200 font-semibold";
   } else if (
    value > this.pbx3cxTableConfig?.answered_percentage_orange_value &&
    value <= this.pbx3cxTableConfig?.answered_percentage_yellow_value &&
    this.pbx3cxTableConfig?.answered_percentage_yellow_active
   ) {
    return "text-yellow-500 bg-yellow-100 font-semibold";
   } else {
    if (
     value > this.pbx3cxTableConfig?.answered_percentage_green_value &&
     this.pbx3cxTableConfig?.answered_percentage_green_active
    ) {
     return "text-green-500 bg-green-200 font-semibold";
    }
   }
  },
  getDurationAvgAnswerColor(value) {
   if (
    value > this.pbx3cxTableConfig?.duration_avg_answer_red_value &&
    this.pbx3cxTableConfig?.duration_avg_answer_red_active
   ) {
    return "text-red-500 bg-red-200 font-semibold bg-red-200";
   } else if (
    value > this.pbx3cxTableConfig?.duration_avg_answer_orange_value &&
    value <= this.pbx3cxTableConfig?.duration_avg_answer_red_value &&
    this.pbx3cxTableConfig?.duration_avg_answer_orange_active
   ) {
    return "text-orange-600 bg-orange-200 font-semibold";
   } else if (
    value > this.pbx3cxTableConfig?.duration_avg_answer_yellow_value &&
    value <= this.pbx3cxTableConfig?.duration_avg_answer_orange_value &&
    this.pbx3cxTableConfig?.duration_avg_answer_yellow_active
   ) {
    return "text-yellow-500 bg-yellow-100 font-semibold";
   } else {
    if (
     value <= this.pbx3cxTableConfig?.duration_avg_answer_green_value &&
     this.pbx3cxTableConfig?.duration_avg_answer_green_active
    ) {
     return "text-green-500 bg-green-200 font-semibold";
    }
   }
  },
  getDurationAvgAnswerColorUser(value) {
   if (
    value > this.pbx3cxTableConfig?.duration_avg_answer_user_red_value &&
    this.pbx3cxTableConfig?.duration_avg_answer_user_red_active
   ) {
    return "text-red-500 bg-red-200 font-semibold bg-red-200";
   } else if (
    value > this.pbx3cxTableConfig?.duration_avg_answer_user_orange_value &&
    value <= this.pbx3cxTableConfig?.duration_avg_answer_user_red_value &&
    this.pbx3cxTableConfig?.duration_avg_answer_user_orange_active
   ) {
    return "text-orange-600 bg-orange-200 font-semibold";
   } else if (
    value > this.pbx3cxTableConfig?.duration_avg_answer_user_yellow_value &&
    value <= this.pbx3cxTableConfig?.duration_avg_answer_user_orange_value &&
    this.pbx3cxTableConfig?.duration_avg_answer_user_yellow_active
   ) {
    return "text-yellow-500 bg-yellow-100 font-semibold";
   } else {
    if (
     value <= this.pbx3cxTableConfig?.duration_avg_answer_user_green_value &&
     this.pbx3cxTableConfig?.duration_avg_answer_user_green_active
    ) {
     return "text-green-500 bg-green-200 font-semibold";
    }
   }
  },
  getDurationAvgCallColor(value) {
   if (
    value > this.pbx3cxTableConfig?.duration_avg_call_red_value &&
    this.pbx3cxTableConfig?.duration_avg_call_red_active
   ) {
    return "text-red-500 bg-red-200 font-semibold bg-red-200";
   } else if (
    value > this.pbx3cxTableConfig?.duration_avg_call_orange_value &&
    value <= this.pbx3cxTableConfig?.duration_avg_call_red_value &&
    this.pbx3cxTableConfig?.duration_avg_call_orange_active
   ) {
    return "text-orange-600 bg-orange-200 font-semibold";
   } else if (
    value > this.pbx3cxTableConfig?.duration_avg_call_yellow_value &&
    value <= this.pbx3cxTableConfig?.duration_avg_call_orange_value &&
    this.pbx3cxTableConfig?.duration_avg_call_yellow_active
   ) {
    return "text-yellow-500 bg-yellow-100 font-semibold";
   } else {
    if (
     value <= this.pbx3cxTableConfig?.duration_avg_call_green_value &&
     this.pbx3cxTableConfig?.duration_avg_call_green_active
    ) {
     return "text-green-500 bg-green-200 font-semibold";
    }
   }
  },
  getRatioSolicitationsColor(value) {
   if (
    value > this.pbx3cxTableConfig?.ratio_solicitations_red_value &&
    this.pbx3cxTableConfig?.ratio_solicitations_red_active
   ) {
    return "text-red-500 bg-red-200 font-semibold";
   } else if (
    value > this.pbx3cxTableConfig?.ratio_solicitations_orange_value &&
    value <= this.pbx3cxTableConfig?.ratio_solicitations_red_value &&
    this.pbx3cxTableConfig?.ratio_solicitations_orange_active
   ) {
    return "text-orange-600 bg-orange-200 font-semibold";
   } else if (
    value > this.pbx3cxTableConfig?.ratio_solicitations_yellow_value &&
    value <= this.pbx3cxTableConfig?.ratio_solicitations_orange_value &&
    this.pbx3cxTableConfig?.ratio_solicitations_yellow_active
   ) {
    return "text-yellow-500 bg-yellow-100 font-semibold";
   } else {
    if (
     value <= this.pbx3cxTableConfig?.ratio_solicitations_green_value &&
     this.pbx3cxTableConfig?.ratio_solicitations_green_active
    ) {
     return "text-green-500 bg-green-200 font-semibold";
    }
   }
  },
  setSorting(sort, header) {
   let sendSort = sort;
   //  console.log(
   //   "setSorting ~ this.waiting_duration_option:",
   //   this.waiting_duration_option
   //  );
   if (sort === "duration_total") {
    sendSort = sort + "_" + this.talking_duration_option;
   }
   if (sort === "waiting_total") {
    sendSort = sort + "_" + this.waiting_duration_option;
   }

   this.$emit("sort", sendSort);
   this.activeSorting = sort;
  },
  focusInputQueue() {
   if (this.$refs.inputSearchQueue) {
    this.$refs.inputSearchQueue[0].focus();
   }
  },
  focusInputQueueName() {
   if (this.$refs.inputSearchQueueName) {
    this.$refs.inputSearchQueueName[0].focus();
   }
  },
  focusInputExtension() {
   if (this.$refs.inputSearchExtension) {
    this.$refs.inputSearchExtension[0].focus();
   }
  },
  focusInputExtensionName() {
   if (this.$refs.inputSearchExtensionName) {
    this.$refs.inputSearchExtensionName[0].focus();
   }
  },
 },
 watch: {
  selectedPeople: function (val) {
   this.$emit("update:selectedPeople", val);
  },
  assignedFilterQueue: function (val) {
   if (val && val.length > 0) {
    this.$emit("update:searchQueue", val);
   } else {
    this.$emit("update:searchQueue", null);
   }
  },
  assignedFilterQueueName: function (val) {
   if (val && val.length > 0) {
    this.$emit("update:searchQueueName", val);
   } else {
    this.$emit("update:searchQueueName", null);
   }
  },
  assignedFilterExtension: function (val) {
   if (val && val.length > 0) {
    this.$emit("update:searchExtension", val);
   } else {
    this.$emit("update:searchExtension", null);
   }
  },
  assignedFilterExtensionName: function (val) {
   if (val && val.length > 0) {
    this.$emit("update:searchExtensionName", val);
   } else {
    this.$emit("update:searchExtensionName", null);
   }
  },
 },
 mounted() {
  if (this.searchQueue) {
   this.assignedFilterQueue = this.searchQueue;
  }
  if (this.searchQueueName) {
   this.assignedFilterQueueName = this.searchQueueName;
  }
  if (this.searchExtension) {
   this.assignedFilterExtension = this.searchExtension;
  }
  if (this.searchExtensionName) {
   this.assignedFilterExtensionName = this.searchExtensionName;
  }
 },
 computed: {
  ...mapGetters([
   "hostName",
   "hostTimeZone",
   "pbxPeriodSelected",
   "pbxElements",
   "pbxMap",
   "pbx3cxTableConfig",
  ]),
  finalBody() {
   let slideTimeRange = parseInt(this.pbx3cxTableConfig?.initial_interval);
   let tableColumnNumber = parseInt(this.pbx3cxTableConfig?.count_interval);
   const timeGap = parseInt(this.pbx3cxTableConfig?.following_interval);
   let durationRanges = [];
   let time1Talking = 0 + slideTimeRange;
   let time1Waiting = 0 + slideTimeRange;
   if (this.selectedRangeType === "talking") {
    for (let index = 0; index < tableColumnNumber; index++) {
     let column = "talking_range_" + time1Talking;
     durationRanges.push(column);
     time1Talking = time1Talking + timeGap;
    }
   }
   if (this.selectedRangeType === "waiting") {
    for (let index = 0; index < tableColumnNumber; index++) {
     let column = "answering_range_" + time1Waiting;
     durationRanges.push(column);
     time1Waiting = time1Waiting + timeGap;
    }
   }
   let tableBody = durationRanges;

   return tableBody;
  },
  finalHeader() {
   let slideTimeRange = parseInt(this.pbx3cxTableConfig?.initial_interval);
   let tableColumnNumber = parseInt(this.pbx3cxTableConfig?.count_interval);
   const timeGap = parseInt(this.pbx3cxTableConfig?.following_interval);
   let durationRanges = [];
   let time1Talking = 0 + slideTimeRange;
   let time1Waiting = 0 + slideTimeRange;
   let startTimeNextTalking = 0;
   let startTimeNextWaiting = 0;
   if (this.selectedRangeType === "talking") {
    for (let index = 0; index < tableColumnNumber; index++) {
     let column = "appel" + " " + startTimeNextTalking + "-" + time1Talking;
     durationRanges.push(column);
     startTimeNextTalking = time1Talking + 1;
     time1Talking = time1Talking + timeGap;
    }
   }
   if (this.selectedRangeType === "waiting") {
    for (let index = 0; index < tableColumnNumber; index++) {
     let column = "" + " " + startTimeNextWaiting + "-" + time1Waiting;
     durationRanges.push(column);
     startTimeNextWaiting = time1Waiting + 1;
     time1Waiting = time1Waiting + timeGap;
    }
   }
   let tableHeader = durationRanges;
   return tableHeader;
  },
  dataDurationRanges() {
   let arrData = [];
   let slideTimeRange = parseInt(this.pbx3cxTableConfig?.initial_interval);
   let tableColumnNumber = parseInt(this.pbx3cxTableConfig?.count_interval);
   const timeGap = parseInt(this.pbx3cxTableConfig?.following_interval);
   const maxTimeRange = slideTimeRange - timeGap + tableColumnNumber * timeGap;
   for (let index = 0; index < this.tableData.length; index++) {
    let data = {};
    let durationRangesData = {};
    let time1Talking = 0 + slideTimeRange;
    let startTimeNextTalking = 0;
    const element = this.tableData[index];
    let keys = Object.keys(element);
    for (let index = 0; index < tableColumnNumber; index++) {
     let talkingRangeValue = 0;
     let waitingRangeValue = 0;
     let talkingMaxRangeValue = 0;
     let waitingMaxRangeValue = 0;
     keys.forEach(key => {
      if (
       (key.includes("talking") || key.includes("answering")) &&
       !key.includes("duration")
      ) {
       let split = key.split("_");
       let timeRange1 = parseInt(split[1]);
       let timeRange2 = parseInt(split[2]);
       if (timeRange2 <= time1Talking && timeRange1 >= startTimeNextTalking) {
        if (key.includes("talking")) {
         talkingRangeValue = talkingRangeValue + parseInt(element[key]);
        } else {
         waitingRangeValue = waitingRangeValue + parseInt(element[key]);
        }
       }
       if (
        index == tableColumnNumber - 1 &&
        startTimeNextTalking <= timeRange1
       ) {
        if (key.includes("talking")) {
         talkingMaxRangeValue = talkingMaxRangeValue + parseInt(element[key]);
        } else {
         waitingMaxRangeValue = waitingMaxRangeValue + parseInt(element[key]);
        }
       }
      }
     });
     if (index < tableColumnNumber - 1) {
      durationRangesData[`${"talking_range" + "_" + time1Talking}`] =
       talkingRangeValue;
      durationRangesData[`${"answering_range" + "_" + time1Talking}`] =
       waitingRangeValue;
     } else {
      durationRangesData[`${"talking_range" + "_" + time1Talking}`] =
       talkingMaxRangeValue;
      durationRangesData[`${"answering_range" + "_" + time1Talking}`] =
       waitingMaxRangeValue;
     }
     startTimeNextTalking = time1Talking + 1;
     time1Talking = time1Talking + timeGap;
    }
    data = { ...element, ...durationRangesData };
    arrData.push(data);
   }
   return arrData;
  },
 },
};
</script>

<style>
.max__body__width {
 max-width: 80px;
}
</style>
