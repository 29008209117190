<template>
  <span class="tooltip text-sm">
    <font-awesome-icon
      icon="circle-info"
      class="h-4 w-4 text-blue-400 hover:text-blue-500 pl-1 pb-1"
    />
    <span class="tooltiptext space-y-1 mx-1">
      <p :class="tooltip2 || tooltipList1 ? 'text-justify' : 'text-center'">
        {{ tooltip1 }}
      </p>
      <p class="text-justify">{{ tooltip2 }}</p>
      <p class="text-justify">{{ tooltip3 }}</p>
      <ul v-if="list" class="text-justify">
        <li :class="tooltipList1 ? 'list-disc list-inside' : ''">
          {{ tooltipList1 }}
        </li>
        <li :class="tooltipList2 ? 'list-disc list-inside' : ''">
          {{ tooltipList2 }}
        </li>
        <li :class="tooltipList3 ? 'list-disc list-inside' : ''">
          {{ tooltipList3 }}
        </li>
        <li :class="tooltipList4 ? 'list-disc list-inside' : ''">
          {{ tooltipList4 }}
        </li>
      </ul>
    </span>
  </span>
</template>

<script>
import { ref } from "vue";

export default {
  props: [
    "list",
    "tooltip1",
    "tooltip2",
    "tooltip3",
    "tooltipList1",
    "tooltipList2",
    "tooltipList3",
    "tooltipList4",
  ],
  components: {},

  data() {
    return {};
  },
  methods: {},

  watch: {},
  mounted() {},
};
</script>
<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: #111827;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -200px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #111827 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
